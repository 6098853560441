type LocaleTypes = "de_DE" | "en_US";
import { GetStaticPropsContext } from "next";
import { Dispatch, SetStateAction } from "react";
import { WP_Post } from "wp-types";
import { BannerType } from "../components/Header/BannerJob/BannerJob";

export type VideoFileType = Omit<ImageType, "mime_type"> & {
  mime_type: "video/mp4";
};

export type PaginationPageType =
  | "insights"
  | "projects"
  | "wiki"
  | "azgrid"
  | "author-insights"
  | "autoren";

export type AuthorInsight = {
  total_posts: number;
  posts: AuthorInsightType[];
};

export type AuthorInsightType = {
  title: string;
  date: string;
  show_updated_datum: boolean;
  updated_datum: string;
  link: string;
  categories: { category_name: string; category_url: string }[];
};

export type PageTypeError = {
  data: {
    status: number;
  };
};

export type ToolTipType = {
  title: string;
  excerpt: string;
  link: string;
};

export type SEOListType = {
  url: string;
  title: string;
  id: number;
};

export type InlineLinkType = {
  id: string;
  name: string;
};

export type PagenationDataType = {
  posts: PageType[];
  total_pages: number;
  total_posts: number;
};
export type CategoryDataType = {
  name: string;
  id: number;
  slug: string;
  link: string;
};

export type ImageType = {
  id: string;
  title: string;
  filename: string;
  url: string | null;
  link: string;
  alt: string;
  author: string;
  name: string;
  date: string;
  modified: string;
  mime_type:
    | "image/jpeg"
    | "image/jpg"
    | "image/webp"
    | "image/png"
    | "image/svg+xml";
  type: "image";
  width: number;
  height: number;
  sizes: {
    thumbnail: string;
    "thumbnail-width": number;
    "thumbnail-height": number;
    medium: string;
    "medium-width": number;
    "medium-height": number;
    medium_large: string;
    "medium_large-width": number;
    "medium_large-height": number;
    large: string;
    "large-width": number;
    "large-height": number;
  };
};

export type Logo = {
  link: LinkType;
  logo: ImageType;
};

export function isAvailable<T>(data: any): data is T {
  return data && typeof data !== "boolean";
}

export type MenuType = {
  term_id: number;
  name: string;
  slug: string;
  taxonomy: "nav_menu";
  parent: number;
  count: number;
  items: MenuItemType[];
};

export type MenuItemType = {
  id: number;
  menu_order: number;
  type: string;
  type_label: string;
  guid: string;
  url: string;
  title: string;
  slug: string;
  target: string;
  classes: string[];
  post_name: string;
  child_items?: any[];
};

export type LinkType = {
  title: string;
  url: string;
  target: string;
};

export type PlatformsType = SinglePlatformType[];

export type SinglePlatformType = {
  name: string;
  link: string;
  icon: ImageType;
};

export type PlatformType = PlatformsType | false;

export type OptionsType = {
  acf: {
    titel: string;
    back: string;
    download: string;
    funktion: string;
    load_more: string;
    read_more: string;
    link: string;
    logos: Logo[];
    logo: ImageType | false;
    webdesign: string;
    social_media: string;
    share_image: ImageType | false;
    share: string;
    you_are_here: string;
    header_text: string;
    search_headline: string;
    search_placeholder: string;
    search_posts_found: string;
    search_results: string;
    header_contact_link: {
      target: string;
      title: string;
      url: string;
    };
    more_terms: string;
    headline_404: string;
    content_404: string;
    subline_404: string;

    image_404: ImageType | false;
    video_404: VideoFileType | false;
    link_404: LinkType;
    link_list_404: { page_link: LinkType }[];
    contact_form: {
      title: string;
      hint: string;
      privacy_policy: string;
      requirement_title: string;
      button: string;
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      requirement: string;
    };
    berlinfahrten_form: {
      title: string;
      hint: string;
      privacy_policy: string;
      button: string;
      email: string;
      name_contact_person: string;
      name_mdb: string;
      phone_number: string;
    };
    newsletter_form: {
      title: string;
      company: string;
      marketing: string;
      right_of_withdrawal: string;
      success_title: string;
      success_message: string;
      register: string;
    };
    success_title: string;
    success_content: string;
    error_title: string;
    error_content: string;
    error_messages: {
      email: string;
      privacy_policy: string;
      required: string;
    };
    plattform: PlatformsType;
    plattforms:
      | {
          name_plattform: string;
          content: string;
          platzhalterbild: ImageType | false;
          label_laden: string;
          label_link_anbieter: string;
          link_anbieter: string;
        }[]
      | boolean;

    kontakt_col_1: string;
    kontakt_col_2: string;
    kontakt_col_3: string;
    headline_kontakt_col_1: string;
    headline_kontakt_col_2: string;
    headline_kontakt_3: string;

    instagram_folgen: string;
    twitter_follow: string;
    youtube_follow: string;

    newsletter_link: {
      title: string;
      target: string;
      url: string;
    };
    newsletter_text: string;
    newsletter_title: string;

    reviews: Review[];
    learn_more: string;

    blogcast: {
      logo: ImageType | false;
      stream: {
        link: LinkType | false;
        streaming: {
          link: LinkType | false;
          image: ImageType | false;
        }[];
        text: string;
      };
      subline: string;
      title: string;
    };

    animation_tooltip: AnimationToolTipType[];
    projects_overview_page: LinkType;
  };
};

export type AnimationToolTipType = {
  id: string;
  text: string;
  link: LinkType | false;
};

export type Review = {
  image: ImageType;
  link: LinkType;
  reviews_number: string;
  stars: string;
  stars_image: string;
  title: string;
};

export type YoastHeadType = {
  title: string;
  description: string;
  robots: {
    index: string;
    follow: string;
    "max-snippet": string;
    "max-image-preview": string;
    "max-video-preview": string;
  };
  canonical: string;
  og_image:
    | [{ height: number; type: string; url: string; width: number }]
    | null;
  og_locale: string;
  og_type: string;
  og_title: string;
  og_description: string;
  og_url: string;
  og_site_name: string;
  article_modified_time: string;
  twitter_card: string;
  schema: any;
};

export type CategoryType = {
  id: number;
  count: number;
  link: string;
  name: string;
  slug: string;
  taxonomy: string;
  parent: number;
  acf: {
    link: string;
    image: ImageType;
  };
  yoast_head_json: YoastHeadType;
};

export type EncyclopediaCategoryType = {
  term_id: number;
  id: number;
  count: number;
  link: string;
  name: string;
  slug: string;
  taxonomy: string;
  parent: number;
  acf: {
    module: any[] | boolean;
    divider: DividerType;
    link?: string;
  };
  yoast_head: string;
  yoast_head_json: YoastHeadType;
};

export type DividerType = {
  bg_text: string;
  divider_on: boolean;
  headline_im_divider: boolean;
  subline: string;
};
export type HeaderStartseiteType = {
  title: string;
  content: string;
  divider: DividerType;
  slogan: string;
  title_size: "normal-size" | "big-size";
  words: {
    text: string;
    upload_video: {
      video_mp4: VideoFileType;
      video_webm: VideoFileType;
      poster: ImageType | false;
    };
  }[];
};

export type CTABoxType = {
  cta_content: string;
  image: ImageType | false;
  image_type: "image" | "svg";
  cta_link: { cta_type: "email" | "link-form"; link: LinkType | "" };
  link: LinkType;
  subline: string;
  svg: ImageType | false;
  svg_animation?: AnimationType | false;
};

export type HeaderSeiteType = {
  header_type:
    | "image"
    | "text"
    | "slider"
    | "ctabox"
    | "hero"
    | "portraitslider"
    | "textmedia"
    | "animationslider"
    | "divider"
    | "wiki";
  title?: string;
  subline?: string;
  text?: string;
  content?: string;
  image: ImageType | false;
  image_tablet?: ImageType;
  image_mobile?: ImageType;
  background:
    | "has-white-bg"
    | "has-grey-bg"
    | "has-dark-grey-bg"
    | "has-darkgrey-bg"
    | "has-gradient-bg";
  slides: ImageType[] | false;
  hpos_class: "headline-beside-text" | "headline-over-text";
  animation: "animation-off" | "animation-on";
  cta_box: CTABoxType;

  divider: DividerType;
  header_height: "fullscreen" | "halfscreen";
  hero_media: heroMediaType;
  media_slider: {
    media: MediaType;
  };
  media_width: "media-is-full" | "media-is-half" | "media-is-three-quarters";
  mpos_class: "is-row-reverse-desktop" | "is-row-normal-desktop";
  number_cols: string;
  cta_type: "link" | "contact-form" | "berlinfahrten-form";
  link: LinkType | "";
  button_label: string;
  portfolio_slider_elements: WP_Post[];
  portraitslider: PortraitSliderType[] | false;
  portraitslider_type:
    | "textmedia-slider"
    | "image-slider"
    | "video-slider"
    | "portfolio-slider"
    | "portrait-slider";
  slider: false;
  with_borders: boolean;
  media: MediaType;
  media_size: "" | "media-is-small";
  animation_slider: animationSliderType;
  single_page_mode: boolean;
  tpos_class: "has-text-centered" | "has-text-left";
  title_size: "normal-size" | "big-size";
  video_oblique: boolean;
};

export type animationSliderElementsType = {
  headline: string;
  content: string;
  cta: LinkType;
};

export type animationSliderType = {
  svg_animation: AnimationType | false;
  svg_animation_mobile: AnimationType | false;
  elements: animationSliderElementsType[] | false;
};

type heroMediaType = {
  hm_image: ImageType | false;
  hm_mobile: ImageType | false;
  hm_tablet: ImageType | false;
  hm_svg: ImageType | false;
  hm_svg_animation: AnimationType | false;
  hm_type: "image" | "video" | "svg";
  object_fit: "cover" | "contain";
  hm_video: {
    video_type: "Youtube" | "Vimeo" | "upload";
    autoplay: boolean;
    sound: boolean;
    hm_video: VideoFileType | false;
    upload_video_mobile: VideoFileType | false;
    upload_video_tablet: VideoFileType | false;
    externes_video: string;
  };
};

export type PortraitSliderType = {
  portraitslider_image_slider: ImageType | boolean;
  portraitslider_title: string;
  portraitslider_overline: string;
  portraitslider_media: PortraitSliderMediaType;
  portraitslider_content: string;
  portraitslider_link: LinkType;
  portraitslider_video: VideoFileType | false;
  portraitslider_poster: ImageType | false;
};

//! need to replace with MediaType
export type PortraitSliderMediaType = {
  portraitslider_media_type: "image" | "video" | "svg";
  portraitslider_media_image: ImageType | false;
  portraitslider_media_svg: ImageType | false;
  portraitslider_media_svg_animation: AnimationType | false;
};

export type HeaderBeitragType = {
  title?: string;
  media?: {
    type: "image" | "video";
    video?: {
      video_type: string;
      externes_video: string | null;
      externes_video_iframe: string;
    };
    image: ImageType | false;
  };
  tags?: { tag: string }[];
  single_page_mode: boolean;
};

export type header_autor = {
  title: string;
  content: string;
  excerpt: string;
  topics: string;
  overline: string;
  modus: string;
  background: string;
  teaser_text: string;
  image: ImageType;
  text: string;
  link?: null | string;
  gender: string;
  single_page_mode: boolean;
};

export type MediaType = {
  image: ImageType | false;
  image_mobile: ImageType | false;
  image_tablet: ImageType | false;
  svg?: ImageType | false;
  svg_animation?: AnimationType | false;
  type: "image" | "video" | "svg" | "svg_animation";
  video: VideoType | false;
};

export type VideoType = {
  autoplay: boolean;
  externes_video: string;
  externes_video_iframe: string;
  internes_video: VideoFileType | false;
  video_size: "big" | "small";
  video_type: string;
  audio: boolean;
};

export type AnimationType = {
  id: string;
  title: string;
  filename: string;
  url: string | null;
  link: string;
  alt: string;
  author: string;
  name: string;
  date: string;
  modified: string;
  mimetype: "application/json";
  type: "application";
  width: number;
  height: number;
};

export type header_project = {
  title: string;
  text: string;
  topics: string;
  background: string;
  image_thumb: ImageType | false;
  video_thumb: VideoFileType | false;
  links: { link: LinkType }[] | boolean;
  logo: ImageType | false;
  media: { media: MediaType };
  overline: string;
  content: string;
  excerpt: string;
  modus: "nav-on" | "nav-off";
  client: string;
  single_page_mode: boolean;
  portrait_slider_headline: string;
  portrait_slider_overline: string;
  portrait_slider_image: ImageType | false;
  show_media: boolean;
  show_images: boolean;
};

export type header_ = {
  title: string;
  text: string;
  topics: string;
  background: string;
  teaser_text: string;
  image: ImageType;
};
export type header_insights = {
  title: string;
  text: string;
  topics: string;
  background: string;
  teaser_text: string;
  author: WP_Post;
  animation: string;
  blogcast: boolean;
  blogcast_iframe: string;
  pos_info: "below-headline" | "over-text-right";
  divider: {
    bg_text: string;
    divider_on: boolean;
    headline_im_divider: boolean;
    subline: string;
  };
  overview: "off" | "on";
  preview_image: ImageType;
  show_updated_datum: boolean;
  updated_datum: string;
  single_page_mode: boolean;
  excerpt: string;
};

export type headerCtaPerson = {
  image: ImageType;
  name: string;
  position: string;
  single_page_mode: boolean;
};

export type HeaderJoinusType = {
  divider: DividerType;
  show_in_jobs: boolean;
  emails: { email: string }[];
  title: string;
  subline: string;
  svg: ImageType;
  content: string;
  animation: "animation-on" | "animation-off";
  background:
    | "has-white-bg"
    | "has-grey-bg"
    | "has-dark-grey-bg"
    | "has-darkgrey-bg"
    | "has-gradient-bg";
  banner: BannerType;
  single_page_mode: boolean;
};

export type header_team = {
  title: string;
  text: string;
  video: VideoFileType;
  image: ImageType;
  switch: boolean;
  single_page_mode: boolean;
};

export type PageType = {
  id: number;
  date: string;
  date_gmt: string;
  modified: string;
  modified_gmt: string;
  slug: string;
  type: string;
  link: string;
  name: string;
  title: {
    rendered: string;
  };
  author: number;
  parent: number;
  categories?: number[];
  project_categories?: string[];
  author_info?: header_autor;
  acf?: {
    header_seite?: HeaderSeiteType;
    header_startseite?: HeaderStartseiteType;
    header_beitrag?: HeaderBeitragType;
    header_autor?: header_autor;
    header_cta_person: headerCtaPerson;
    header_joinus: HeaderJoinusType;
    wiki_header?: HeaderWikiType;
    module?: any[] | false;
    header_insights?: header_insights;
    header_project?: header_project;
    header_team?: header_team;
    link?: string;
    header_logos: header_logos;
  };
  "project-category": number[];
  wpml_current_locale: LocaleTypes;
  wpml_translations?: WPML_TranslationType[];
  yoast_head_json: YoastHeadType;
  _embedded: {
    "wp:term": [CategoryType[]];
  };
};
export interface PaginationPostType {
  [PageType: string]: WikiType[];
}
export interface FilterChars {
  [letter: string]: string;
}
export interface AzFilterPostType {
  posts: WikiType[] | null;
  filteredChar: string | null;
  filterChars?: FilterChars;
  postsTotal: number;
}

export type header_logos = {
  logo_type: "svg" | "image";
  image: ImageType | false;
  svg: ImageType | false;
  "svg-animation": AnimationType | false;
  link?: LinkType;
};
export interface SearchWikiType {
  posts: WikiType[] | null;
  keyword: string | null;
  postsTotal: number;
}
export interface GetStaticPropsContextWithQuery extends GetStaticPropsContext {
  query?: { keyword?: string; page?: number };
}
export interface AppContextType {
  showInlineNav: boolean;
  setShowInlineNav: Dispatch<SetStateAction<boolean>>;
  inlineNavInView: boolean;
  setInlineNavInView: Dispatch<SetStateAction<boolean>>;
  inlineLinks: InlineLinkType[];
  setInlineLinks: Dispatch<SetStateAction<InlineLinkType[]>>;
  search: string;
  searchPosts: AzFilterPostType;
  setSearchPosts: Dispatch<SetStateAction<AzFilterPostType>>;
  setSearch: Dispatch<SetStateAction<string>>;
  //Wikis States
  setAzFilterPosts: Dispatch<SetStateAction<AzFilterPostType>>;
  azFilterPosts: AzFilterPostType;
  azFilter: string;
  setAzFilter: Dispatch<SetStateAction<string>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  wikiPostsPerPage: number;
  setWikiPostsPerPage: Dispatch<SetStateAction<number>>;
}
export interface WikiType extends PageType {
  "encyclopedia-category": number[];
}
export type HeaderWikiType = {
  content?: string;
  cta_content?: string;
  has_cta: boolean;
  link_automatically: boolean;
  headline?: string;
  link: LinkType | null;
  synonyms: boolean | string[];
  title_subline?: string;
  activeCategories: EncyclopediaCategoryType[];
  single_page_mode: boolean;
  background: string;
  abstand: "is-gapless-top" | "is-gapless-top-bottom";
};

export type WPML_TranslationType = {
  locale: LocaleTypes;
  id: number;
  post_title: string;
  href: string;
};
//! :suggestion insted of adding additional value its better to extend this type below in the module/component
export type LayoutType = {
  id: string;
  background: string;
  abstand:
    | "default"
    | "is-gapless-top"
    | "is-gapless-bottom"
    | "is-gapless"
    | "is-gapless-top-bottom";
  hpos_class?: string;
  tpos_class?: string;
  mpos_class?: string;
  ctapos_class?: string;
  cta_pos?: "cta-is-left" | "cta-is-right";
  number_cols?: string;
  pinned?: boolean;
  shadow_animation?: boolean;
  split: "one-third-screen" | "half-screen";
  width?: string;
  htype?: "h2" | "h3";
  with_line?: boolean | false;
  bild_typ: "normal" | "round";
  infobox_pos?: "infobox-is-left" | "infobox-is-right";
  malign_class?: "is-vcentered" | "is-vtop";
  hsize_tag?: "normal" | "h2";
};

export type NextJSStaticPathType = {
  params: { dynamicRoute: string[] };
  locale?: string;
};
